/** @format */

import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import localForage from "localforage";
import rootReducers from "./root.reducers"; // where reducers is a object of reducers
import rootSagas from "./root.sagas";
const persistConfig = {
  key: "persist_key",
  storage: localForage,
};
const middleware = [];
const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);
middleware.push(createLogger());

const enhancers = [applyMiddleware(...middleware)];
// const initialState = {};
// const persistConfig = { enhancers };
const persistedReducer = persistReducer(persistConfig, rootReducers);
const store = createStore(persistedReducer, compose(...enhancers));
const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);

export default store;
export { persistor };

export const baseName = "/";
export const playIcon = "images/login/plyicncopy.png";
export const resumeIcon = "images/login/resumeIcon.png";
export const BuyIcon = "images/login/icons8-rent-movie-50.png";
export const subscribeTitle = "Subscribe";

export const subscribeUrl = "https://dishhome.com.np/dishhome-go/packages";
// export const subscribeUrl = "http://demo1.dishhome.com.np/dishhome-go/packages";
// export const subscribeUrl = "https://www.riversilica.com/";

export const splitNumber = "1";

// //*********Dev

// export const serverIpPath = "https://soapboxplayer.riversilica.com/webappDev";
// export const nodeimprovisationServerPath =
//   "https://soapboxplayer.riversilica.com/dbcacheDev";
// export const websocketPathForCheckDevice =
//   "soapboxplayer.riversilica.com/websocketDev/?deviceId=";

// //*********Testing

export const serverIpPath = "https://soapboxplayer.riversilica.com/rswebappws";
export const nodeimprovisationServerPath =
  "https://soapboxplayer.riversilica.com/rsdbcache";
export const websocketPathForCheckDevice =
  "soapboxplayer.riversilica.com/rswebsocket/?deviceId=";
// ----------------------------------

// //***************staging

// export const serverIpPath = "https://dmnott.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   //  "https://dmnott.dishhome.com.np/soapboxdbcache";  // old
//   "https://dmnott.dishhome.com.np/soapboxcache"; //new

// export const websocketPathForCheckDevice =
//   "dmnott.dishhome.com.np/websocket/?deviceId=";

// export const serverIpPath = "https://soapbox.dishhome.com.np/rswebappws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/nodeimprovisation";

// export const serverIpPath = "https://soapbox.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/soapboxdbcache";

// // ******sms

// export const serverIpPath =
//   "https://soapboxplayer.riversilica.com/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapboxplayer.riversilica.com/soapboxdbcache";
// export const websocketPathForCheckDevice =
//   "soapboxplayer.riversilica.com/websocket/?deviceId=";

// export const serverIpPath = "https://soapbox.dishhome.com.np/soapboxwebws";
// export const nodeimprovisationServerPath =
//   "https://soapbox.dishhome.com.np/dhdbcacheV2";

// export const websocketPath = "soapbox.dishhome.com.np";

// export const websocketPathForCheckDevice =
//   "soapbox.dishhome.com.np/dhwebsocket/?deviceId=";

// export const serverIpPath = "https://dmnott.dishhome.com.np/rswebappws";
// export const nodeimprovisationServerPath =
//   "https://dmnott.dishhome.com.np/rsdbcache";

// export const websocketPathForCheckDevice =
//   "dmnott.dishhome.com.np/rswebsocket/?deviceId=";

// fro GIT

// export const serverIpPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":3006/soapboxwebws";
// export const nodeimprovisationServerPath =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":5010/dhdbcache";

// export const websocketPathForCheckDevice =
//   window.location.hostname + ":3001/websocket/?deviceId=";
//&&&&&&&*()_ ...//

// export const serverIpPath = "http://45.198.13.40/soapboxwebws";
// export const nodeimprovisationServerPath = "http://45.198.13.40/dhdbcache";
// export const websocketPathForCheckDevice =
//   "45.198.13.40/rswebsocket/?deviceId=";

// export const serverIpPath =
//   window.location.protocol + "//" + window.location.hostname + "/soapboxwebws";
// //  "http://localhost:3006/soapboxwebws/";
// export const nodeimprovisationServerPath =
//   window.location.protocol + "//" + window.location.hostname + "/dhdbcache";

// export const websocketPathForCheckDevice =
//   window.location.hostname + "/websocket/?deviceId=";

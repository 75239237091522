// import { Alert } from "react-native";
import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";
var qs = require("qs");

export const getDrmDetailsAsync = async () => {
  const ConstantHeaders = HeadersAcessToken();

  // Alert.alert("platform service is working");
  return await axiosInstance()
    .get("/drmDetails", {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      console.log("drmDetails" + JSON.stringify(response));
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      // alert(JSON.stringify(error));
      return Promise.reject(error.response.data);
    });
};
